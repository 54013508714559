const ReimbursementReasons = [
  {
    _id: '653fa0e0a15cf0003c116ab5',
    reason: 'Dining Benefit',
  },
  {
    _id: '653fa0e0a15cf0003c116ab3',
    reason: 'Equipment Allowance',
  },
  {
    _id: '653fa0e0a15cf0003c116ab2',
    reason: 'Fuel Reimbursement',
  },
];

export default ReimbursementReasons;