import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import DateFnsUtils from '@date-io/date-fns';
import Input from '@material-ui/core/Input';
import moment from 'moment';
import { Col, Form, Row } from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Toaster from '../../../components/Toaster';

import DatePickerInput from '../../../components/DatePickerInput/DatePickerInput';
import TimePickerInput from '../../../components/TimePickerInput/TimePickerInput';

import { useAuthContext } from '../../../contexts/user-context';
import { getUserFlexPayLimit } from '../../../services/users-service';
import {
  minuteToDateTime,
  minuteToTimeFullStr,
  timeToMinutes,
} from '../../../utils/TimesheetManagement';
import '../css/timesheet.css';

const DateTimeDetails = memo(({ formContent, showTimeError }) => {
  const { authUser } = useAuthContext();

  const {
    control,
    formState: { errors },
    setValue,
    register,
    setError,
  } = useFormContext();

  const [tsDate, setTsDate] = useState(
    formContent?.two?.ts_date
      ? moment.utc(formContent.two.ts_date).format('MM/DD/YYYY')
      : moment().format('MM/DD/YYYY')
  );
  const [arriveTime, setarriveTime] = useState(
    formContent?.two?.arriveTime || formContent?.two?.arriveTime === 0
      ? minuteToDateTime(formContent.two.arriveTime)
      : null
  );
  const [departureTime, setdepartureTime] = useState(
    formContent?.two?.departureTime || formContent?.two?.departureTime === 0
      ? minuteToDateTime(formContent.two.departureTime)
      : null
  );
  const [driveTime, setdriveTime] = useState(
    formContent?.two?.driveTime ? formContent.two.driveTime : null
  );
  const [arriveMinutes, setarriveMinutes] = useState(undefined);
  const [departureMinutes, setdepartureMinutes] = useState(undefined);
  const [totalTime, setTotalTime] = useState(
    formContent?.two?.totalTime ? formContent.two.totalTime : 0
  );

  const [flexPayHours, setFlexPayHours] = useState(
    formContent?.two?.flexPayHours ? formContent?.two?.flexPayHours : null
  );
  const [flexPayHoursLimit, setFlexPayHoursLimit] = useState(0);
  const [isChecked, setIsChecked] = useState(false);

  const [isTrainer, setisTrainer] = useState(formContent.two?.trainer === true);

  const [futureDisabled, setfutureDisabled] = useState(false);
  const [minDate, setMinDate] = useState();
  const [maxDate, setMaxDate] = useState();
  const [limitExceeded, setLimitExceeded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loader, setLoader] = useState(false);
  const { timesheetId } = useParams();
  const isAddMode = !timesheetId;

  useEffect(() => {
    switch (authUser.user.role.rolename) {
      case 'Admin':
      case 'Manager':
        setfutureDisabled(true);
        setMinDate(new Date('1900-01-01'));
        break;

      case 'Chef':
      case 'Office':
        setfutureDisabled(true);
        // setMinDate(moment().subtract('1', 'days').toDate());
        setMinDate(moment().startOf('week').toDate());
        setMaxDate(moment().endOf('week').toDate());
        break;

      case 'Accountant':
        break;

      default:
        setfutureDisabled(true);
        break;
    }
  }, []);

  useEffect(() => {
    if (arriveTime) {
      setarriveMinutes(timeToMinutes(arriveTime));
      setValue('arriveTime', timeToMinutes(arriveTime), {
        shouldDirty: true,
        shouldValidate: true,
      });
      if (arriveTime && !departureTime) {
        setError(
          'departureTime',
          { type: 'required', message: 'Departure time is required.' },
          { shouldFocus: true }
        );
      }
    } else {
      setValue('arriveTime', arriveTime, {
        shouldDirty: false,
        shouldValidate: true,
      });
    }
  }, [arriveTime]);

  useEffect(() => {
    if (departureTime) {
      setdepartureMinutes(timeToMinutes(departureTime));
      setValue('departureTime', timeToMinutes(departureTime), {
        shouldDirty: true,
        shouldValidate: true,
      });
      if (departureTime && !arriveTime) {
        setError(
          'arriveTime',
          { type: 'required', message: 'Arrive time is required.' },
          { shouldFocus: true }
        );
      }
    } else {
      setValue('departureTime', arriveTime, {
        shouldDirty: false,
        shouldValidate: true,
      });
    }
  }, [departureTime]);

  useEffect(() => {
    if (driveTime) {
      setValue('driveTime', driveTime, {
        shouldDirty: true,
        shouldValidate: true,
      });
    } else {
      setValue('driveTime', driveTime, {
        shouldDirty: false,
        shouldValidate: true,
      });
    }
  }, [driveTime]);

  useEffect(() => {
    if (flexPayHours) {
      setValue('flexPayHours', flexPayHours, {
        shouldDirty: true,
        shouldValidate: true,
      });
    } else {
      setValue('flexPayHours', flexPayHours, {
        shouldDirty: false,
        shouldValidate: true,
      });
    }
  }, [flexPayHours]);

  useEffect(() => {
    if (moment.utc(formContent?.two?.ts_date).format('MM/DD/YYYY') === tsDate) {
      setValue('ts_date', tsDate, { shouldDirty: false, shouldValidate: true });
    } else {
      setValue('ts_date', tsDate, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [tsDate]);

  useEffect(() => {
    if (Number.isNaN(driveTime)) {
      setdriveTime(null);
      setValue('driveTime', null, { shouldDirty: true, shouldValidate: true });
    }
  }, [driveTime]);

  useEffect(() => {
    setValue('totalTime', totalTime, {
      shouldDirty: true,
      shouldValidate: true,
    });
  }, [totalTime]);

  useEffect(() => {
    setValue('trainer', isTrainer, { shouldDirty: true, shouldValidate: true });
  }, [isTrainer]);

  useEffect(() => {
    let total = 0;
    if (isChecked) {
      setarriveTime(null);
      setdepartureTime(null);
      setdepartureMinutes(null);
      setarriveMinutes(null);

      total = 0;
      if (flexPayHours !== null && !Number.isNaN(flexPayHours)) {
        const flexPayHoursInMinutes = Math.round(Number(flexPayHours) * 60 * 100) / 100;;
        total += flexPayHoursInMinutes;
      }
    } else if (!isChecked) {
      setFlexPayHours(null);
      if (departureMinutes !== undefined && arriveMinutes !== undefined && flexPayHours !== undefined &&
        departureMinutes !== null && arriveMinutes !== null
      ) {
        if (departureMinutes > arriveMinutes) {
          total = departureMinutes - arriveMinutes;
        } else {
          total = departureMinutes + 1440 - arriveMinutes;
        }
      } else {
        total = 0;
      }
    }

    if (driveTime !== null && !Number.isNaN(driveTime)) {
      total += driveTime;
    }

    if (total && total !== undefined) {
      setTotalTime(total);
    } else {
      setTotalTime(0);
    }
  }, [arriveMinutes, departureMinutes, driveTime, flexPayHours, isChecked]);

  async function getUserCurrFlexPayLimit(employeeId) {
    try {
      setLoader(true);
      const response = await trackPromise(getUserFlexPayLimit(employeeId));
      const currLimit = response?.data?.data?.flexPayBalance;
      setLoader(false);
      return currLimit;
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  useEffect(async () => {
    setIsChecked(!!formContent?.two?.flexPayHours);
    if (formContent?.two?.flexPayHours) {
      setFlexPayHours(formContent?.two?.flexPayHours);
    }
    const employeeId = formContent?.one.employee.employeeId;
    const currLimit = await getUserCurrFlexPayLimit(employeeId);
    setFlexPayHoursLimit(currLimit);

  }, [formContent]);

  const handleCheckboxClick = () => {
    setisTrainer(!isTrainer);
  };

  const handleCheckboxChange = async () => {
    setIsChecked(!isChecked);
    const employeeId = formContent?.one.employee.employeeId;
    const currLimit = await getUserCurrFlexPayLimit(employeeId);
    setFlexPayHoursLimit(currLimit);
  };

  const handleFlexPayHoursChange = (event) => {
    event.preventDefault();

    let value = event.target.value.replace(/[^0-9.]/g, "")
    if (value.startsWith(".")) {
      value = `0${value}`;
    }

    if (isAddMode) {
      if (flexPayHoursLimit === 0) {
        setLimitExceeded(false);
        setFlexPayHours(0);
      } else if (value >= 0 && value <= flexPayHoursLimit) {
        setLimitExceeded(false);
        setFlexPayHours(value);
      } else {
        setFlexPayHours(0);
        setLimitExceeded(true);
        setErrorMessage(value < 0 ? "Negative values not allowed" : `Remaining limit: ${flexPayHoursLimit} Hrs`);
        toast.dark(
          <Toaster
            icon="error"
            message={`Hours should be equal or less than ${flexPayHoursLimit} Hrs.`}
          />
        );
      }
    } else {
      const totalFlexHoursLimit = flexPayHoursLimit + flexPayHours;
      const remainingLimit = totalFlexHoursLimit - Number(value);

      if (value < 0 || value > totalFlexHoursLimit) {
        setLimitExceeded(true);
        setErrorMessage(value < 0 ? "Negative values not allowed" : `Remaining limit: ${totalFlexHoursLimit} Hrs`);
        setFlexPayHours(0);
        setFlexPayHoursLimit(totalFlexHoursLimit);
        toast.dark(
          <Toaster
            icon="error"
            message='Hours should be equal or less than remaining limit.'
          />
        );
        return;
      }

      setLimitExceeded(false);
      setErrorMessage('');
      setFlexPayHours(Number(value));
      setFlexPayHoursLimit(remainingLimit);
    }
  };

  return (
    <div className="container">
      <Row>
        <Col md="8" className="mx-auto p-0">
          <Row className="pl-2 pr-0">
            <Col md="6" xs="12" className='custom-row-height'>
              <Form.Group controlId="exampleForm.SelectCustom">
                <Row className="pl-0">
                  <Col md="12">
                    <Form.Label className="txt-light-secondary">
                      Date <span className="txt-primary">*</span>
                    </Form.Label>
                  </Col>
                  <Col md="12">
                    <Controller
                      render={() => (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePickerInput
                            selectedDate={tsDate}
                            handleDateChange={(val) =>
                              setTsDate(moment(val).format('MM/DD/YYYY'))
                            }
                            disableFuture={futureDisabled}
                            minDate={minDate}
                            maxDate={maxDate}
                          />
                        </MuiPickersUtilsProvider>
                      )}
                      name="ts_date"
                      control={control}
                    />
                    {errors.ts_date && (
                      <p className="text-danger">{errors.ts_date.message}</p>
                    )}
                  </Col>
                </Row>
              </Form.Group>
            </Col>
            {authUser.user.role.rolename !== 'Office' && (
              <Col md="6" xs="12" className='custom-row-height'>
                <Form.Group controlId="exampleForm.SelectCustom">
                  <Row className="pl-0">
                    <Col md="12">
                      <Form.Label className="txt-light-secondary">
                        Drive Time (in minutes)
                      </Form.Label>
                    </Col>
                    <Col md="12">
                      <Controller
                        render={({ field: { name } }) => (
                          <Input
                            name={name}
                            placeholder="Enter drive time in minutes"
                            fullWidth
                            value={driveTime}
                            inputProps={{ 'aria-label': 'description', min: 0 }}
                            onChange={(event) => {
                              setdriveTime(
                                Number.parseInt(event.target.value, 10)
                              )
                            }}
                            onKeyPress={(e) => {
                              // Prevent the entry of the character 'e'
                              if (e.key === 'e' || e.key === 'E') {
                                e.preventDefault();
                              }
                            }}
                            // onKeyPress={(event) =>
                            // {
                            //   console.log({ code: event.code });
                            //   return (event.charCode === 8 ||
                            //     event.charCode === 0 ||
                            //     event.charCode === 13) ? null : event.charCode >= 48 && event.charCode <= 57
                            // }}
                            type="Number"
                          />
                        )}
                        name="driveTime"
                        control={control}
                      />
                      {errors.driveTime ? (
                        <p className="text-danger">
                          {errors.driveTime.message}
                        </p>
                      ) : (
                        <p className="text-muted font-italic">
                          Note: You’ll need approval from the manager.
                        </p>
                      )}
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            )}
          </Row>
          <Row className="pl-2 pr-0">
            <Col md="6" xs="12" className='flex-pay-hours'>
              <Form.Group controlId="formGroupCheckbox" className="pt-4">
                <input
                  type="checkbox"
                  className="ftc-flex-pay-hours-checkbox"
                  id="flex-pay-hours"
                  onChange={handleCheckboxChange}
                  checked={isChecked}
                />
                <label
                  className="position-relative ml-4 pl-2"
                  htmlFor="flex-pay-hours"
                >
                  Flex Pay Hours
                </label>
              </Form.Group>
            </Col>
            {
              isChecked && (
                <>
                  <Col md="6" xs="12" className='flex-time'>
                    <Form.Group controlId="numberInput">
                      <Row className="pl-0">
                        <Col md="12">
                          <Form.Label className="txt-light-secondary">Flex Pay Hours</Form.Label>
                          <span style={{
                            fontSize: '15px',
                            position: 'absolute',
                            right: '20px',
                            top: errors.flexPayHours ? '47%' : '70%',
                            transform: 'translateY(-50%)',
                            zIndex: 50,
                            color: '#A50907',
                            background: '#fff',
                            padding: '2px 10px 2px 10px',
                          }}>
                            {limitExceeded ? (
                              <>{errorMessage}</>
                            ) : (
                              <>
                                {loader ? '...' : `${flexPayHoursLimit.toFixed(2)} `} Hours Remaining
                              </>
                            )}
                          </span>

                          <Form.Control
                            {...register('flexPayHours')}
                            type="text"
                            placeholder="Enter hours"
                            value={flexPayHours}
                            // onInput={(e) => limitDecimalPlaces(e, 2)}
                            onChange={(e) => handleFlexPayHoursChange(e)}
                            onKeyPress={(e) => {
                              // Prevent the entry of the character 'e'
                              if (e.key === 'e' || e.key === 'E') {
                                e.preventDefault();
                              }
                            }}
                          />
                          {errors.flexPayHours && (
                            <p className="text-danger">{errors.flexPayHours.message}</p>
                          )}
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                </>
              )
            }
          </Row>
          <Row className="pl-2 pr-0">
            <Col md="6" xs="12" className='custom-row-height'>
              <Form.Group controlId="exampleForm.SelectCustom">
                <Row className="pl-0">
                  <Col md="12">
                    <Form.Label className="txt-light-secondary">
                      Arrive Time
                    </Form.Label>
                  </Col>
                  <Col md="12">
                    {/* <Controller
                      render={() => ( */}
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <TimePickerInput
                        selectedTime={arriveTime}
                        handleTimeChange={setarriveTime}
                        disabled={isChecked}
                      />
                    </MuiPickersUtilsProvider>
                    {/* )}
                      name='arriveTime'
                      control={control}
                    /> */}

                    {showTimeError && errors.arriveTime && (
                      <p className="text-danger">{errors.arriveTime.message}</p>
                    )}
                  </Col>
                </Row>
              </Form.Group>
            </Col>
            <Col md="6" xs="12" className='custom-row-height'>
              <Form.Group controlId="exampleForm.SelectCustom">
                <Row className="pl-0">
                  <Col md="12">
                    <Form.Label className="txt-light-secondary">
                      Depart Time
                    </Form.Label>
                  </Col>
                  <Col md="12">
                    {/* <Controller
                      render={() => ( */}
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <TimePickerInput
                        selectedTime={departureTime}
                        handleTimeChange={setdepartureTime}
                        disabled={isChecked}
                      />
                    </MuiPickersUtilsProvider>
                    {/* )}
                      name='departureTime'
                      control={control}
                    /> */}

                    {showTimeError && errors.departureTime && (
                      <p className="text-danger">
                        {errors.departureTime.message}
                      </p>
                    )}
                  </Col>
                </Row>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md="6" className="pl-2 pr-1">
              <div className="label-readonly">
                <span className="mt-1">
                  <label>Total Time</label>
                  <span className="float-right">
                    <strong>
                      {totalTime ? (
                        <label>{minuteToTimeFullStr(totalTime)}</label>
                      ) : (
                        '--'
                      )}
                    </strong>
                  </span>
                </span>
              </div>
            </Col>
            {formContent?.one?.employee.trainer ? (
              <Col md="6" xs="12">
                <Row>
                  <Col md="12" xs="12" className="pl-2">
                    <Form.Group controlId="formBasicCheckbox">
                      <Form.Check
                        {...register('trainer')}
                        checked={isTrainer}
                        onChange={handleCheckboxClick}
                        type="checkbox"
                        label="This was Trainer time"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            ) : (
              ''
            )}
          </Row>
          <div className="ml-2 mr-3 border-bottom" />
        </Col>
      </Row>
    </div>
  );
});

export default DateTimeDetails;

DateTimeDetails.propTypes = {
  formContent: PropTypes.any.isRequired,
  showTimeError: PropTypes.bool.isRequired,
};
