import React, { memo } from 'react';
import { Row, Form, Col, Image } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import SettingsDropdown from '../../../components/SettingsDropdown/SettingsDropdown';
import {
  getPaymentStatusLabel,
  DateConvert,
  getAcknowledgeStatus
} from '../../../utils/TimesheetManagement';
// import { getUserStatusLabel } from '../../../utils/UserManagement';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});
const ReimbursementMobileTable = memo(
  ({
    reimbursement,
    handleViewClick,
    handleClickDelete,
    callback,
    isEditable,
    isDeletable,
  }) => {
    const classes = useStyles();

    return (
      <div
        className="mt-3"
        onClick={(event) => {
          const settingsDropDown = event.target.closest('#settings-dropdown');
          if (!settingsDropDown) {
            callback(reimbursement);
          }
        }}
        onKeyDown={() => { }}
        role="button"
        tabIndex={0}
      >
        <Card className={`${classes.root} mt-2`}>
          <CardContent>
            <Row className="pl-0 pb-0 pt-0 border-bottom">
              <Col xs="6">
                <Form.Group className="mb-0" controlId="ts-employee">
                  <Form.Label className="mb-0 txt-light-secondary">
                    Employee
                  </Form.Label>
                  <Form.Label className="d-flex">
                    {reimbursement.employee.username}
                  </Form.Label>
                </Form.Group>
              </Col>

              <Col xs="6">
                <Form.Group className="mb-0" controlId="ts-client">
                  <Form.Label className="mb-0 txt-light-secondary">
                    Reason
                  </Form.Label>
                  <Form.Label className="d-flex">
                    {reimbursement.reason.reason ?? 'N/A'}
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col xs="12" className="pr-0 ml-2">
                <span className="ellipse__cities__mobile float-right">
                  <SettingsDropdown
                    identifier={reimbursement.reimbursementId}
                    handleViewClick={handleViewClick}
                    handleClickDelete={handleClickDelete}
                    isEditable={isEditable}
                    isDeletable={isDeletable}
                  />
                </span>
              </Col>
            </Row>
            <Row className="border-bottom pl-0 pb-0 pt-0">
              <Col className="pt-2" xs="8">
                <Form.Group className="mb-0" controlId="ts-totaldue">
                  <Image
                    className="mr-2"
                    src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-money.svg`}
                  />
                  <Form.Label className="mt-1">
                    <span>${reimbursement.total_amount} Due</span>
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col className="pt-2 pl-2 pr-0" xs="4">
                <Form.Group className="mb-0" controlId="formBasicServiceCity">
                  <Form.Label
                    className={`hover-text ${reimbursement.payment_status === 'pending'
                      ? 'label-pending-sm'
                      : 'label-active-sm'
                      }`}
                  >
                    <span className="tooltip-text" id="bottom">{reimbursement?.markAsPaidBy?.username}</span>
                    <div
                      className={
                        reimbursement.payment_status === 'pending'
                          ? 'pending-ellipse'
                          : 'active-ellipse'
                      }
                    />
                    {` `}
                    {getPaymentStatusLabel(reimbursement.payment_status)}
                  </Form.Label>
                </Form.Group>
              </Col>
            </Row>
            <Row className="border-bottom pl-0 pb-0 pt-0">
              <Col className="pt-2" xs="8">
                <Form.Group className="mb-0" controlId="ts-totaldue">
                  <Form.Label className="mb-0 mr-4 txt-light-secondary">
                    Status
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col className="pt-2 pl-2 pr-0" xs="4">
                <Form.Group className="mb-0" controlId="formBasicServiceCity">
                  <Form.Label
                    className={`hover-text ${reimbursement?.reimbursement_status === 'pending'
                      ? 'label-pending-sm'
                      : reimbursement?.reimbursement_status === 'denied'
                        ? 'label-deny-sm'
                        : 'label-active-sm'
                      }`}
                  >
                    <span className="tooltip-text" id="bottom">{reimbursement?.reimbursementAcknowledgedBy?.username}</span>
                    <div
                      className={
                        // eslint-disable-next-line no-nested-ternary
                        reimbursement?.reimbursement_status === 'pending'
                          ? 'pending-ellipse'
                          : reimbursement?.reimbursement_status === 'denied'
                            ? 'deny-ellipse'
                            : 'active-ellipse'
                      }
                    />
                    {` `}
                    {getAcknowledgeStatus(reimbursement?.reimbursement_status)}
                  </Form.Label>
                </Form.Group>
              </Col>
            </Row>
            <Row className="border-bottom pl-0 pb-1">
              <Col xs="12">
                <Form.Group className="mb-0 d-flex justify-content-between" controlId="formBasicServiceCity">
                  <Form.Label className="mb-0 mr-4 txt-light-secondary">
                    Reason
                  </Form.Label>
                  <p className="mb-1">{reimbursement.reason.reason}</p>
                </Form.Group>
              </Col>
            </Row>
            <Col className="pt-2 pl-0" xs="5">
              <Form.Group className="mb-0" controlId="ts-date">
                <Form.Label>
                  {DateConvert(reimbursement.reimbursement_date)}
                </Form.Label>
              </Form.Group>
            </Col>
          </CardContent>
        </Card>
      </div>
    );
  }
);

export default ReimbursementMobileTable;

ReimbursementMobileTable.propTypes = {
  reimbursement: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  handleViewClick: PropTypes.func.isRequired,
  //  handleEditClicked: PropTypes.func.isRequired,
  handleClickDelete: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isDeletable: PropTypes.bool.isRequired,
};
