import React, { memo } from 'react'
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';

import { KeyboardDatePicker } from '@material-ui/pickers';

const DatePickerInput = memo(({
    selectedDate, handleDateChange, dateFormat, ref, disabled, disableFuture, minDate, maxDate, rounded,
}) => {
    return (
        <KeyboardDatePicker
            className={rounded && 'rounded'}
            clearable
            placeholder="Select Date"
            value={selectedDate || null}
            onChange={(date) => handleDateChange(date)}
            format={dateFormat}
            // ref={ref}
            innerRef={ref}
            // showTodayButton
            disabled={disabled}
            InputProps={{
                readOnly: true,
            }}
            keyboardIcon={<Image
                className="ftc-logo-24"
                src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-calendar.svg`}
            />}
            disableFuture={disableFuture}
            minDate={minDate}
            maxDate={maxDate || new Date('2100-01-01')}
            minDateMessage='You can only create timesheet for the Current Work Week!'
            maxDateMessage='You can only create timesheet for the Current Work Week!'
            clearLabel={false}
        />
    )
});

export default DatePickerInput;

DatePickerInput.propTypes = {
    selectedDate: PropTypes.any.isRequired,
    handleDateChange: PropTypes.func.isRequired,
    dateFormat: PropTypes.string,
    ref: PropTypes.any,
    disabled: PropTypes.bool,
    disableFuture: PropTypes.bool,
    minDate: PropTypes.any.isRequired,
    maxDate: PropTypes.any,
};

DatePickerInput.defaultProps = {
    dateFormat: 'MM/dd/yyyy',
    ref: () => { },
    disabled: false,
    disableFuture: false,
    maxDate: null
}