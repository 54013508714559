import { Input, TablePagination } from '@material-ui/core';
import React, { memo, useEffect, useRef, useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormControl,
  Image,
  InputGroup,
  Modal,
  Row,
} from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { toast } from 'react-toastify';
// import * as Scroll from 'react-scroll';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import exportIcon from '../../assets/images/exportIcon.svg';
import ConfirmAlertModal from '../../components/ConfirmAlertModal/ConfirmAlertModal';
import DataTableComponent from '../../components/DataTableComponent';
import NoCustomPageComponent from '../../components/NoCustomPageComponent/NoCustomPageComponent';
import RenderCustomPopup from '../../components/RenderCustomPopup';
import TablePaginationActions from '../../components/TablePaginationActions/TablePaginationActions';
import Toaster from '../../components/Toaster';
import { useAuthContext } from '../../contexts/user-context';
import {
  getPeriodsByYear,
  getPeriodsLtYear,
} from '../../services/period-service';
import {
  deleteReimbursementById,
  getAllReimbursements,
} from '../../services/reimbursement-service';
import useQuery from '../../utils/CustomHooks/useQuery';
import {
  DateConvert,
  getAcknowledgeStatus,
  getPaymentStatusLabel,
  showExportButton,
} from '../../utils/TimesheetManagement';
import { validatePermission } from '../../utils/UserManagement';
import { Desktop, Mobile } from '../media-queries/mediaQueries';
import TableMobileView from './mobile-layouts/ReimbursementMobileTable';
// import TablePaginationActions from '../../../components/TablePaginationActions/TablePaginationActions';
import Loader from '../../assets/images/loader.svg';
import DatePickerInput from '../../components/DatePickerInput/DatePickerInput';
import constant from '../../constant';
import { getAllReimbursementReasons } from '../../services/reimbursement_reason.services';
import { exportToCSV } from '../../utils/ExportDataToCSV';
import Sidebar from '../side-navbar/Sidebar';

const DEFAULT_VALUE = '-';

const reimbursement = memo(() => {
  const { authUser } = useAuthContext();
  const history = useHistory();
  const apiCallRef = useRef(false);
  // const { scroller } = Scroll;
  const animatedComponents = makeAnimated();
  const isIndeterminate = (indeterminate) => indeterminate;
  const selectableRowsComponentProps = { indeterminate: isIndeterminate };
  const URLQuery = useQuery();
  const [isAPICalled, setAPICalled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reimbursementCount, setReimbursementCount] = useState({
    totalCount: 0,
    filterCount: 0,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [reimbursements, setReimbursements] = useState([]);
  const [employee, setEmployee] = useState(URLQuery.get('employee') ?? '');
  const [searchQuery, setSearchQuery] = useState(
    URLQuery.get('employee') ?? ''
  );

  const [allYears, setAllYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);

  const [periods, setPeriods] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [selectedRow, setSelectedRow] = useState();
  const [showConfirmAlert, setConfirmAlert] = useState(false);
  const [exportAPICalled, setExportAPICalled] = useState(false);
  const [reimbursementReasons, setReimbursementReasons] = useState([]);
  const [selectedReimbursementReason, setSelectedReimbursementReason] = useState(null);
  const [selectedSubmissionDate, setSelectedSubmissionDate] = useState({
    startDate: null,
    endDate: null,
  });

  const [minAmount, setMinAmount] = useState('');
  const [maxAmount, setMaxAmount] = useState('');

  // Modal
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const handleCloseConfirm = () => setConfirmAlert(false);

  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = useState('');

  const [filterState, setFilterState] = useState({
    period: {},
    reimbursementReason: '',
  })

  const [reqQuery, setReqQuery] = useState({
    period: { startDate: '', endDate: '' },
    periodYear: '',
    submissionDate: { startDate: null, endDate: null },
    reimbursementReason: '',
    totalAmount: { minAmount: '', maxAmount: '' }
  })

  const columns = [
    {
      name: 'Name',
      selector: (row) => row.employee?.username || DEFAULT_VALUE,
      sortable: true,
    },
    {
      name: 'Reason',
      selector: (row) => row?.reason?.reason || DEFAULT_VALUE,
      sortable: true,
    },
    {
      name: 'Date',
      selector: (row) => DateConvert(row.reimbursement_date),
      sortable: true,
    },
    {
      name: 'Amount',
      selector: (row) => `$${row?.total_amount}`,
      sortable: true,
    },
    {
      name: 'Status',
      minWidth: '120px',
      cell: (row) => {
        // const status = 'active'
        const { reimbursement_status: rstatus } = row;

        return (
          // eslint-disable-next-line react/destructuring-assignment
          <div title={row?.reimbursementAcknowledgedBy?.username}>
            <label
              className={
                rstatus === 'pending'
                  ? 'label-pending'
                  : rstatus === 'denied'
                    ? 'label-deny'
                    : 'label-active'
              }
            >
              <span
                className={
                  // eslint-disable-next-line no-nested-ternary
                  rstatus === 'pending'
                    ? 'pending-ellipse'
                    : rstatus === 'denied'
                      ? 'deny-ellipse'
                      : 'active-ellipse'
                }
              />
              {` `}
              {getAcknowledgeStatus(rstatus)}
            </label>
          </div>
        );
      },
    },
    {
      name: 'Payment',
      minWidth: '120px',
      cell: (row) => {
        // const status = 'active'
        const { payment_status: status } = row;
        return (
          // eslint-disable-next-line react/destructuring-assignment
          <div title={row?.markAsPaidBy?.username}>
            <label
              className={
                status === 'pending' ? 'label-pending' : 'label-active'
              }
            >
              <span
                className={
                  status === 'pending' ? 'pending-ellipse' : 'active-ellipse'
                }
              />
              {` `}
              {getPaymentStatusLabel(status)}
            </label>
          </div>
        );
      },
    },
  ];

  const handleRowClicked = (row) => {
    history.push(`/viewReimbursement/${row.reimbursementId}`);
  };

  const onAddNew = () => {
    history.push('/addNewReimbursement');
    // history.push(`/viewReimbursement/1`);
  };

  const handleViewClick = (state) => {
    history.push(`/viewReimbursement/${state.target.id}`);
  };

  const handleClickDelete = (state) => {
    setSelectedRow(state.target.id);
    setConfirmAlert(true);
  };
  const onSearchClick = () => {
    setSearchQuery(employee);
    setPage(0);
  };

  const storeReimbursementFilter = (reqQueryData, filterStateData) => {
    localStorage.setItem("reimbursement-filter", JSON.stringify(reqQueryData));
    localStorage.setItem("reimbursement-filter-period", JSON.stringify(filterStateData));
  }

  async function fetchAllReimbursements(reqQueryData, filterStateData, manualTrigger = false) {
    if (!manualTrigger && apiCallRef.current) return;

    try {
      let str;
      if (reqQueryData && filterStateData) {
        str = JSON.stringify(reqQueryData);

        storeReimbursementFilter(reqQueryData, filterStateData);
      } else {
        str = JSON.stringify(reqQuery);
      }

      const queryString = `&filter=${str}`;
      const response = await trackPromise(
        getAllReimbursements(rowsPerPage, page, searchQuery, queryString)
      );
      setReimbursements(response.data.data.reimbursements);
      setReimbursementCount({
        totalCount: response.data.data.totalCount,
        filterCount: response.data.data.filterCount,
      });
      setAPICalled(true);
      // Set the ref to true after API call is made
      apiCallRef.current = true;

    } catch (error) {
      const errorMessage = error?.response?.data?.message || 'Something went wrong!!!';
      toast.dark(<Toaster icon="error" message={errorMessage} />);
    } finally {
      setLoading(false);
    }
  }

  const resetPeriod = () => {
    setReqQuery(prev => ({
      ...prev,
      period: { startDate: "", endDate: "" }
    }))
    setFilterState(prevState => ({
      ...prevState,
      period: {}
    }))
  }

  const resetReimbursementReason = () => {
    setFilterState(prevState => ({
      ...prevState,
      reimbursementReason: '',
    }))
    setReqQuery(prev => ({
      ...prev,
      reimbursementReason: '',
    }))
  }

  useEffect(() => {
    const filters = JSON.parse(localStorage.getItem('reimbursement-filter'));
    const filterPeriod = JSON.parse(localStorage.getItem('reimbursement-filter-period'));
    if (filters) {
      setReqQuery({
        period: {
          startDate: filters.period.startDate,
          endDate: filters.period.endDate,
        },
        periodYear: filters.periodYear,
        submissionDate: {
          startDate: filters.submissionDate.startDate,
          endDate: filters.submissionDate.endDate,
        },
        reimbursementReason: filters.reimbursementReason,
        totalAmount: {
          minAmount: filters.totalAmount.minAmount,
          maxAmount: filters.totalAmount.maxAmount,
        }
      });

      setSelectedYear(filters.periodYear);
      if (filterPeriod) {
        setSelectedPeriod(filterPeriod.period);
      }
      setSelectedReimbursementReason(filterPeriod.reimbursementReason);
      setSelectedSubmissionDate({
        startDate: filters.submissionDate.startDate,
        endDate: filters.submissionDate.endDate,
      });
      setMinAmount(filters.totalAmount.minAmount);
      setMaxAmount(filters.totalAmount.maxAmount);
    }

    if (filterPeriod) {
      setFilterState({
        period: filterPeriod.period,
        reimbursementReason: filterPeriod.reimbursementReason,
      })
    }
    if (filters && filterPeriod) {
      apiCallRef.current = false;
      fetchAllReimbursements(filters, filterPeriod, true);
    } else {
      storeReimbursementFilter(reqQuery, filterState)
    }
  }, [])

  async function fetchAllReimbursementReasons() {
    try {
      const response = await trackPromise(
        getAllReimbursementReasons(rowsPerPage, page, searchQuery)
      );

      setReimbursementReasons(response.data.data.reimbursement_reasons);
      setAPICalled(true);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  };

  async function fetchAllPagesReimbursements() {
    try {
      setExportAPICalled(true);
      const str = JSON.stringify(reqQuery);
      const queryString = `&filter=${str}`;
      const response = await trackPromise(
        getAllReimbursements(reimbursementCount.totalCount, 0, searchQuery, queryString)
      );
      const transformedData = response.data.data.reimbursements.map(item => ({
        Name: item.employee.username,
        Reason: item.reason.reason,
        date: DateConvert(item.reimbursement_date),
        Amount: item.total_amount,
        reimbursementStatus: item.reimbursement_status,
        paymentStatus: item.payment_status,
      }));
      exportToCSV(transformedData, `reimbursement_data_${DateConvert(new Date())}`);
      setExportAPICalled(false);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
      setExportAPICalled(false);
    }
  }

  const onDeleteReimbursement = async () => {
    try {
      const response = await trackPromise(deleteReimbursementById(selectedRow));
      toast.dark(
        <Toaster icon="notify" message={`${response.data.message}`} />,
        { autoClose: 3000, hideProgressBar: true }
      );
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    } finally {
      setConfirmAlert(false);
      apiCallRef.current = false;
      fetchAllReimbursements(reqQuery, filterState, true);
      // history.replace('/reimbursement');
    }
  };

  // const onMenuOpen = (elementName) => {
  //   scroller.scrollTo(elementName, {
  //     duration: 1500,
  //     delay: 100,
  //     smooth: true,
  //     offset: 50, // Scrolls to element + 50 pixels down the page
  //   });
  // };

  async function fetchAllPeriodsYears() {
    try {
      const response = await trackPromise(
        getPeriodsLtYear(new Date().getFullYear())
      );
      setAllYears(response.data.data.periods);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }
  async function fetchAllPeriods() {
    try {
      let response;
      if (selectedYear) {
        response = await trackPromise(
          getPeriodsByYear(selectedYear.year, new Date())
        );
      }
      const totalPeriods = response.data.data.periods.length;
      setPeriods(
        response.data.data.periods.map((period, index) => {
          const tempPeriod = period;
          tempPeriod.label = `Period ${totalPeriods - index} (${DateConvert(
            period.start_date
          )} - ${DateConvert(period.end_date)})`;
          tempPeriod.value = period.period_index;
          return tempPeriod;
        })
      );
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  const handleApplyFilters = () => {
    if (selectedSubmissionDate.startDate || selectedSubmissionDate.endDate) {
      if (!selectedSubmissionDate.startDate || !selectedSubmissionDate.endDate) {
        toast.dark(
          <Toaster
            icon="error"
            message="Select both Submission dates"
          />
        );
        return;
      }
    }

    if (minAmount && maxAmount) {
      const minimumAmount = Number(minAmount);
      const maximumAmount = Number(maxAmount);
      if (minimumAmount > maximumAmount) {
        toast.dark(
          <Toaster
            icon="error"
            message="Minimum amount should be lesser than maximum amount."
          />
        );
        return;
      }
    }

    handleClose();

    const updatedQuery = { ...reqQuery };
    const updatedFilters = { ...filterState };

    // Period Year
    if (selectedYear) {
      fetchAllPeriods();
      updatedQuery.periodYear = selectedYear;
    }

    // Period
    if (selectedPeriod) {
      updatedFilters.period = selectedPeriod;
      updatedQuery.period = {
        startDate: selectedPeriod.start_date,
        endDate: selectedPeriod.end_date,
      };
    }

    // Submission Date Range
    if (selectedSubmissionDate.startDate && selectedSubmissionDate.endDate) {
      const startDate = new Date(selectedSubmissionDate.startDate);
      startDate.setUTCDate(startDate.getUTCDate());
      startDate.setUTCHours(0, 0, 0, 0);
      const startOfDay = startDate.toISOString();

      const endDate = new Date(selectedSubmissionDate.endDate);
      endDate.setUTCDate(endDate.getUTCDate());
      endDate.setUTCHours(23, 59, 59, 999);
      const endOfDay = endDate.toISOString();

      updatedQuery.submissionDate = {
        startDate: startOfDay,
        endDate: endOfDay
      };
    }

    // Reimbursement Reason
    if (selectedReimbursementReason) {
      updatedQuery.reimbursementReason = selectedReimbursementReason._id;
      updatedFilters.reimbursementReason = selectedReimbursementReason;
    }

    // Amount
    updatedQuery.totalAmount = {};
    if (minAmount) {
      updatedQuery.totalAmount.minAmount = minAmount;
    }

    if (maxAmount) {
      updatedQuery.totalAmount.maxAmount = maxAmount;
    }

    if (!minAmount || !maxAmount) {
      updatedQuery.totalAmount.minAmount = minAmount;
      updatedQuery.totalAmount.maxAmount = maxAmount;
    }

    setReqQuery(updatedQuery);
    setFilterState(updatedFilters);
    apiCallRef.current = false;
    fetchAllReimbursements(updatedQuery, updatedFilters, true);
  };

  const handleClearFilters = () => {
    setReqQuery({
      period: { startDate: '', endDate: '' },
      periodYear: '',
      submissionDate: { startDate: null, endDate: null },
      reimbursementReason: '',
      totalAmount: { minAmount: '', maxAmount: '' }
    });
    setFilterState({ period: '', reimbursementReason: '' })
    setSelectedYear(null);
    setSelectedPeriod(null);
    setPeriods(null)
    setSelectedReimbursementReason(null);
    setSelectedSubmissionDate({ startDate: null, endDate: null });
    setMinAmount('');
    setMaxAmount('');
    const reqQueryData = {
      period: { startDate: '', endDate: '' },
      periodYear: '',
      submissionDate: { startDate: null, endDate: null },
      reimbursementReason: '',
      totalAmount: { minAmount: '', maxAmount: '' }
    };
    const filterStateData = { period: '', reimbursementReason: '' };
    apiCallRef.current = false;
    fetchAllReimbursements(reqQueryData, filterStateData, true);
  }

  const removeSelectedFilter = (filterName) => {

    const reqQueryValues = { ...reqQuery };
    const filterStateValues = { ...filterState };

    const resetState = (key, defaultValue) =>
      setReqQuery(prevState => ({
        ...prevState,
        [key]: defaultValue,
      }));

    const stateDefaults = {
      periodYear: '',
      period: { startDate: '', endDate: '' },
      submissionDate: { startDate: null, endDate: null },
      reimbursementReason: '',
      totalAmount: { minAmount: '', maxAmount: '' }
    };

    switch (filterName) {
      case "periodYear":
        setSelectedYear(null);
        reqQueryValues.periodYear = { startDate: '', endDate: '' };
        resetState(filterName, stateDefaults[filterName]);
        break;

      case "period":
        setSelectedPeriod(null);
        setFilterState(prevState => ({
          ...prevState,
          period: {},
        }));
        reqQueryValues.period = { startDate: '', endDate: '' };
        filterStateValues.period = {};
        resetState(filterName, stateDefaults[filterName]);
        break;

      case "submissionDate":
        setSelectedSubmissionDate({ startDate: null, endDate: null });
        reqQueryValues.submissionDate = { startDate: null, endDate: null };
        resetState(filterName, stateDefaults[filterName]);
        break;

      case "reimbursementReason":
        setSelectedReimbursementReason(null);
        setFilterState(prevState => ({
          ...prevState,
          reimbursementReason: '',
        }));
        reqQueryValues.reimbursementReason = '';
        filterStateValues.reimbursementReason = '';
        resetState(filterName, stateDefaults[filterName]);
        break;

      case "totalAmount":
        setMinAmount('');
        setMaxAmount('');
        reqQueryValues.totalAmount = { minAmount: '', maxAmount: '' };
        resetState(filterName, stateDefaults[filterName]);
        break;

      default:
        return;
    }

    storeReimbursementFilter(reqQueryValues, filterStateValues);
    fetchAllReimbursements(reqQueryValues, filterStateValues, true);
  }

  const reimbursementFilters = () => {
    return (
      <Row className="pb-0 d-flex flex-column">
        <Col>
          <Form.Group controlId="formBasicEmail">
            <Form.Label className="txt-light-secondary">Period Year</Form.Label>
            <Select
              name="period_year_options"
              id="periodYearOptionScroll"
              isMulti={false}
              closeMenuOnSelect
              components={animatedComponents}
              options={allYears}
              value={selectedYear}
              getOptionLabel={(option) => option.year}
              getOptionValue={(option) => option.year}
              onChange={(option) => {
                if (option === null) {
                  setSelectedYear(null);
                  setReqQuery(prevState => ({
                    ...prevState,
                    periodYear: ""
                  }));
                } else {
                  setSelectedYear(option);
                }

                setSelectedPeriod(null);
                setReqQuery(prevState => ({
                  ...prevState,
                  period: { startDate: '', endDate: '' },
                }));
                setFilterState(prevState => ({
                  ...prevState,
                  period: {}
                }))
              }}
              styles={{
                clearIndicator: (base) => ({
                  ...base,
                  cursor: 'pointer',
                  padding: '5px',
                }),
                menu: (styles) => ({ ...styles, zIndex: 999 }),
              }}
              onMenuOpen={() => {
                // onMenuOpen('periodYearOptionScroll');
              }}
              isClearable
              menuPlacement="auto"
              classNamePrefix="select"
              menuShouldScrollIntoView
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formBasicEmail">
            <Form.Label className="txt-light-secondary">Period</Form.Label>
            <Select
              name="period_options"
              id="periodOptionScroll"
              isMulti={false}
              closeMenuOnSelect
              components={animatedComponents}
              options={periods}
              value={selectedPeriod}
              disabled
              onChange={(option) => {
                if (option === null) {
                  setSelectedPeriod(null);
                  resetPeriod();
                } else {
                  setSelectedPeriod(option);
                }
              }}
              styles={{
                clearIndicator: (base) => ({
                  ...base,
                  cursor: 'pointer',
                  padding: '5px',
                }),
                menu: (styles) => ({ ...styles, zIndex: 999 }),
              }}
              onMenuOpen={() => {
                // onMenuOpen('periodOptionScroll');
              }}
              isClearable
              menuPlacement="auto"
              classNamePrefix="select"
              menuShouldScrollIntoView
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formBasicEmail">
            <Form.Label className="txt-light-secondary">Submission Date Range</Form.Label>
            <div className="time-picker-container w-100">
              <Col className='col-time-picker p-0 m-0'>
                <label>From</label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePickerInput
                    rounded
                    selectedDate={selectedSubmissionDate.startDate}
                    handleDateChange={(val) => {
                      setSelectedSubmissionDate(prev => ({
                        ...prev,
                        startDate: val,
                      }))
                    }}
                    disabled={false}
                    allowClear
                  />
                </MuiPickersUtilsProvider>
              </Col>
              <Col className='col-time-picker p-0 m-0'>
                <label>To</label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePickerInput
                    rounded
                    selectedDate={selectedSubmissionDate.endDate}
                    handleDateChange={(value) => {
                      setSelectedSubmissionDate(prevState => ({
                        ...prevState,
                        endDate: value,
                      }));
                    }}
                    disabled={false}
                    allowClear
                  />
                </MuiPickersUtilsProvider>
              </Col>
            </div>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formBasicEmail">
            <Form.Label className="txt-light-secondary">Reason</Form.Label>
            <Select
              name="period_options"
              id="periodOptionScroll"
              isMulti={false}
              closeMenuOnSelect
              components={animatedComponents}
              options={reimbursementReasons}
              getOptionLabel={(option) => option.reason}
              getOptionValue={(option) => option._id}
              value={selectedReimbursementReason}
              onChange={(option) => {
                if (option === null) {
                  setSelectedReimbursementReason(null);
                  resetReimbursementReason();
                } else {
                  setSelectedReimbursementReason(option)
                }
              }}
              styles={{
                clearIndicator: (base) => ({
                  ...base,
                  cursor: 'pointer',
                  padding: '5px',
                }),
                menu: (styles) => ({ ...styles, zIndex: 999 }),
              }}
              isClearable
              classNamePrefix="select"
              menuShouldScrollIntoView
              menuPlacement="auto"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formBasicEmail">
            <Form.Label className="txt-light-secondary">Amount</Form.Label>
            <div className="amount-container">
              <Input
                type="text"
                placeholder="Minimum Amount"
                className="amount-input"
                onChange={(e) => {
                  const { value } = e.target;
                  if (/^\d*$/.test(value)) {
                    setMinAmount(value);
                  }
                }}
                value={minAmount}
              />
              <Input
                type="text"
                placeholder="Maximum Amount"
                className="amount-input"
                value={maxAmount}
                onChange={(e) => {
                  const { value } = e.target;
                  if (/^\d*$/.test(value)) {
                    setMaxAmount(value);
                  }
                }}
              />
            </div>
          </Form.Group>
        </Col>
      </Row>
    );
  };

  const displaySelectedFilters = () => {
    const filters = { ...reqQuery };
    const filterPeriod = JSON.parse(localStorage.getItem('reimbursement-filter-period'));
    if (!filters || !filterPeriod) return null;

    let amount;
    if (filters?.totalAmount?.minAmount) {
      amount = `${filters?.totalAmount?.minAmount} - Max`;
    }

    if (filters?.totalAmount?.maxAmount) {
      amount = `0 - ${filters?.totalAmount?.maxAmount}`;
    }

    if (filters?.totalAmount?.minAmount && filters?.totalAmount?.maxAmount) {
      amount = `${filters?.totalAmount?.minAmount} - ${filters?.totalAmount?.maxAmount}`
    }

    const filterItems = [
      {
        label: 'Period Year',
        value: filters?.periodYear?.year,
        filterName: 'periodYear',
      },
      {
        label: 'Period',
        value: filterPeriod?.period?.label,
        filterName: 'period',
      },
      {
        label: 'Submission Dates',
        value: filters?.submissionDate.startDate && filters?.submissionDate.endDate ?
          `${moment.utc(filters?.submissionDate.startDate).format('MM/DD/YYYY')} - ${moment.utc(filters?.submissionDate.endDate).format('MM/DD/YYYY')}` : '',
        filterName: 'submissionDate',
      },
      {
        label: 'Reason',
        value: filterPeriod?.reimbursementReason?.reason,
        filterName: 'reimbursementReason',
      },
      {
        label: 'Amount',
        value: amount,
        filterName: 'totalAmount',
      },
    ];

    const hasAnyValue = filterItems.some(item => item.value);

    return hasAnyValue ? (
      <Row>
        {filterItems.map(
          (item) =>
            item.value && (
              <span className="filter-label" key={item.label}>
                <span className="txt-light-secondary">{item.label}:</span> {item.value || '-'}{' '}
                <Image
                  className='cross-btn'
                  src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
                  fluid
                  onClick={() => removeSelectedFilter(item.filterName)}
                />
              </span>
            )
        )}

        <span>
          <Button
            variant="link"
            onClick={handleClearFilters}
            className="float-right txt-primary"
          >
            Clear Filter
          </Button>
        </span>
      </Row>
    ) : null;
  };

  const filterModal = () => {
    return (
      <Modal
        show={openModal}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {reimbursementFilters()}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="default"
            className="pr-6 pl-6 pt-2 pb-2 mb-3 mr-3 rounded-bottom"
            onClick={handleClearFilters}
          >
            Clear All
          </Button>
          <Button
            variant="default"
            className="pr-6 pl-6 pt-2 pb-2 mb-3 mr-3 rounded-bottom border-red txt-primary btn-ftc-border-primary"
            onClick={handleApplyFilters}
          >
            Apply Changes
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  useEffect(() => {
    fetchAllPeriodsYears();
    fetchAllReimbursementReasons();
  }, []);

  useEffect(() => {
    if (selectedYear) {
      fetchAllPeriods();
    }
  }, [selectedYear]);

  // useEffect(() => {
  //   fetchAllReimbursements(reqQuery, filterState);
  // }, [rowsPerPage, page, searchQuery, query, reqQuery]);

  return (
    <div>
      <Row className="mr-0">
        <Desktop>
          <Col md="2">
            <Sidebar />
          </Col>
        </Desktop>
        <Mobile>
          <Sidebar />
        </Mobile>
        {isAPICalled && !reimbursementCount.totalCount ? (
          <NoCustomPageComponent
            title="Reimbursements"
            onAddNew={onAddNew}
            addNewText='Start adding your first ”Reimbursement"'
            isAddNew={validatePermission(
              authUser.user.role.permissions,
              'reimbursement',
              'add'
            )}
          />
        ) : (
          <>
            <Desktop>
              <Col md="10">
                <div className="container">
                  <Row className="border-bottom sticky-dashboard-header">
                    <Col md={4} lg={4}>
                      <h3>
                        <strong>Reimbursement</strong>
                      </h3>
                    </Col>
                    <Col />
                    <Col md={4} lg={5} className="pl-0 d-flex">
                      <Button
                        variant="default"
                        className="d-flex flex-row justify-content-center mr-3 mb-4 pl-3 pr-3"
                        onClick={handleOpen}
                      >
                        <Image
                          className="mt-1"
                          src={
                            Object.values(reqQuery).some((value) =>
                              typeof value === 'object'
                                ? Object.values(value).some((v) => v)
                                : value
                            )
                              ? `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter-data.svg`
                              : `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter.svg`
                          }
                          fluid
                        />
                        <span className="pl-1">Filter</span>
                      </Button>
                      {filterModal()}
                      <InputGroup className="mb-4">
                        <FormControl
                          placeholder="Search Employees"
                          aria-label="Employees"
                          aria-describedby="basic-addon1"
                          value={employee}
                          onChange={(e) => setEmployee(e.target.value)}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              setSearchQuery(event.target.value);
                              setPage(0);
                            }
                          }}
                        />
                        <InputGroup.Prepend>
                          <Button
                            variant="default"
                            className="btn-ftc-primary-light"
                            onClick={onSearchClick}
                          >
                            Search
                          </Button>
                        </InputGroup.Prepend>
                      </InputGroup>
                    </Col>
                    {showExportButton(authUser.user.role.rolename) && (
                      <Col xl={1 / 2} className="mr-3">
                        <span className="float-left">
                          <button
                            type="button"
                            className="btn btn-ftc-primary-light-border float-right"
                            onClick={() => fetchAllPagesReimbursements()}
                          >
                            {exportAPICalled ? (
                              <>
                                <Image
                                  src={Loader}
                                  height="18px"
                                  width="18px"
                                />
                              </>
                            ) : (
                              <>
                                <Image src={exportIcon} /> Export
                              </>
                            )}
                          </button>
                        </span>
                      </Col>
                    )}

                    {validatePermission(
                      authUser.user.role.permissions,
                      'reimbursement',
                      'add'
                    ) && (
                        <div className="pr-4">
                          <button
                            type="button"
                            className="btn btn-ftc-primary float-right"
                            onClick={onAddNew}
                          >
                            Add New
                          </button>
                        </div>
                      )}
                  </Row>
                  <div className="container-top-7">
                    {loading ?
                      (
                        <div />
                      ) :
                      (displaySelectedFilters())
                    }
                    <div>
                      <Row className="pt-0">
                        <Col md="12" className="pr-0">
                          {isAPICalled ? (
                            <>
                              <DataTableComponent
                                // title="Movie List"
                                highlightOnHover
                                pointerOnHover
                                noHeader
                                columns={columns}
                                data={reimbursements}
                                pagination
                                selectableRowsComponentProps={
                                  selectableRowsComponentProps
                                }
                                paginationServer
                                paginationTotalRows={
                                  reimbursementCount.filterCount
                                }
                                handleRowClicked={handleRowClicked}
                                paginationPerPage={rowsPerPage}
                                onChangePage={(p) => {
                                  setPage(p - 1);
                                  // look for better solution later
                                  // setPage(p)
                                }}
                                onChangeRowsPerPage={(rws, pg) => {
                                  setRowsPerPage(rws);
                                  setPage(pg);
                                }}
                              />
                              <TablePagination
                                labelRowsPerPage="Rows per page:"
                                component="div"
                                count={Number.parseInt(
                                  reimbursementCount.filterCount,
                                  10
                                )}
                                page={page}
                                onChangePage={(event, newPage) => {
                                  setPage(newPage);
                                }}
                                defaultPage={0}
                                rowsPerPage={rowsPerPage}
                                onChangeRowsPerPage={(event) => {
                                  setRowsPerPage(
                                    parseInt(event.target.value, 10)
                                  );
                                  setPage(0);
                                }}
                                ActionsComponent={TablePaginationActions}
                              />
                            </>
                          ) : (
                            ''
                          )}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
            </Desktop>
            <Mobile>
              <Col>
                <div className="container pr-0">
                  <Row className="border-bottom pr-0 sticky-dashboard-header-mobile">
                    <Col xs="9" className="ml-4">
                      <h3>Reimbursement</h3>
                    </Col>
                    <Col xs="2">
                      <Image
                        className="ftc-logo-40"
                        src={`${process.env.PUBLIC_URL}/assets/ftc-mobile-logo-2.svg`}
                      />{' '}
                    </Col>
                  </Row>
                  <div className="container-top-20">
                    <Row className="mobile-btn-view">
                      {showExportButton(authUser.user.role.rolename) && (
                        <div>
                          <button
                            type="button"
                            className="export-btn btn btn-block"
                            onClick={() => fetchAllPagesReimbursements()}
                          >
                            {exportAPICalled ? (
                              <>
                                <Image
                                  src={Loader}
                                  height="16px"
                                  width="18px"
                                  className="export-img"
                                />
                              </>
                            ) : (
                              <>
                                <Image src={exportIcon} />
                                Export
                              </>
                            )}
                          </button>
                        </div>
                      )}

                      <div>
                        <button
                          type="button"
                          className="btn btn-ftc-primary btn-block"
                          onClick={onAddNew}
                        >
                          Add New
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="filter-btn btn btn-block"
                          onClick={handleOpen}
                        >
                          <Image
                            className="mr-2"
                            src={
                              Object.values(reqQuery).some((value) =>
                                typeof value === 'object'
                                  ? Object.values(value).some((v) => v)
                                  : value
                              )
                                ? `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter-data.svg`
                                : `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter.svg`
                            }
                            fluid
                          />
                          Filter
                        </button>
                        {filterModal()}
                      </div>
                    </Row>
                    <Row className="pt-0 pb-0">
                      <Col xs="12" className="pr-1 pl-1">
                        <Form.Control
                          type="text"
                          placeholder="Search"
                          value={employee}
                          // onChange={(e) => setCityName(e.target.value)}
                          onChange={(e) => setEmployee(e.target.value)}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              setSearchQuery(event.target.value);
                              setPage(0);
                            }
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      {reimbursements.map((reimbursementDetail) => {
                        return (
                          <Col
                            md={6}
                            key={reimbursementDetail._id}
                            className="pr-1 pl-1"
                          >
                            <TableMobileView
                              reimbursement={reimbursementDetail}
                              callback={handleRowClicked}
                              handleViewClick={handleViewClick}
                              handleClickDelete={handleClickDelete}
                              isEditable
                              isDeletable={false}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                    {isAPICalled && !reimbursementCount.filterCount && (
                      <div className="text-center">
                        <p>There are no records to display.</p>
                      </div>
                    )}
                    <Row>
                      <Col xs="12">
                        <TablePagination
                          className="mobile__pagination"
                          labelRowsPerPage=""
                          ActionsComponent={TablePaginationActions}
                          component="div"
                          count={Number.parseInt(
                            reimbursementCount.filterCount,
                            10
                          )}
                          page={page}
                          // onChangePage={handleChangePage}
                          onChangePage={(event, newPage) => {
                            setPage(newPage);
                          }}
                          defaultPage={0}
                          rowsPerPage={rowsPerPage}
                          onChangeRowsPerPage={(event) => {
                            setRowsPerPage(parseInt(event.target.value, 10));
                            setPage(0);
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Mobile>
          </>
        )}
      </Row>
      {showConfirmAlert && (
        <ConfirmAlertModal
          show={showConfirmAlert}
          handleClose={handleCloseConfirm}
          title="Delete Reimbursement"
          description={constant.DELETE_REIMBURSEMENT_POPUP_MESSAGE}
          callback={onDeleteReimbursement}
          action="Delete it"
          closeAction="Cancel"
        />
      )}
      <RenderCustomPopup />
    </div>
  );
});

export default reimbursement;
