/* eslint-disable react/react-in-jsx-scope */
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import Toaster from '../components/Toaster';
import '../layouts/Timesheet/css/timesheet.css';
import { Mobile } from '../layouts/media-queries/mediaQueries';
import { validatePermission } from "./UserManagement";
import { generateHoursReport } from '../services/hours-report.service';

const getAcknowledgeStatus = (status) => {
  switch (status) {
    case 'pending':
      return 'Pending';
    case 'approved':
      return 'Approved';
    case 'denied':
      return 'Denied';
    default:
      return null;
  }
};

const checkApproveDenyButton = (
  roleName,
  permissions,
  status,
  reimbursementAcknowledge,
  acknowledgedBy
) => {
  const showButtons = (
    <>
      <Button
        variant="primary"
        className="btn-ftc-primary  mr-2"
        onClick={() => reimbursementAcknowledge('approved')}
      >
        Approve
      </Button>
      <Button
        variant="default"
        className="btn-ftc-border-primary"
        onClick={() => reimbursementAcknowledge('denied')}
      >
        Deny
      </Button>
    </>
  );
  const approveDenyLabel = (
    <div title={acknowledgedBy} className="hover-text">
      <Mobile><span className="tooltip-text" id="bottom">{acknowledgedBy}</span></Mobile>
      <label
        className={
          status === 'denied'
            ? 'label-deny'
            : status === 'approved'
              ? 'label-active'
              : 'label-pending'
        }
      >
        <span
          className={
            // eslint-disable-next-line no-nested-ternary
            status === 'denied'
              ? 'deny-ellipse'
              : status === 'approved'
                ? 'active-ellipse'
                : 'pending-ellipse'
          }
        />
        {` `}
        {getAcknowledgeStatus(status)}
      </label>
    </div>
  );
  switch (roleName) {
    case 'Admin':
      if (status === 'pending') {
        return showButtons;
      }
      if (status === 'approved' || status === 'denied') {
        return approveDenyLabel;
      }
      break;

    case 'Manager':
      if (status === 'pending') {
        return showButtons;
      }
      if (status === 'approved' || status === 'denied') {
        return approveDenyLabel;
      }
      break;

    case 'Office':
      return approveDenyLabel;

    case 'Chef':
      return approveDenyLabel;

    case 'Accountant':
      return approveDenyLabel;

    default:
      return validatePermission(permissions, 'reimbursement', 'edit');
  }
};

const getPaymentStatusLabel = (status) => {
  switch (status) {
    case 'pending':
      return 'Pending';
    case 'paid':
      return 'Paid';
    default:
      return null;
  }
};

const checkPaymentButton = (
  roleName,
  permissions,
  status,
  isAcknowledged,
  handlePayment,
  markAsPaidBy
) => {
  const isDisabled = isAcknowledged === 'pending' || isAcknowledged === 'denied';
  const showButton = (
    <>
      <Button
        variant="primary"
        className="btn-ftc-primary  mr-2"
        onClick={handlePayment}
        disabled={isDisabled}
      >
        Mark As a Paid
      </Button>
    </>
  );
  const paymentLabel = (
    <div title={markAsPaidBy} className="hover-text">
      <Mobile><span className="tooltip-text" id="bottom">{markAsPaidBy}</span></Mobile>
      <label
        className={status === 'pending' ? 'label-pending' : 'label-active'}
      >
        <span
          className={
            status === 'pending' ? 'pending-ellipse' : 'active-ellipse'
          }
        />
        {` `}
        {getPaymentStatusLabel(status)}
      </label>
    </div>
  );
  switch (roleName) {
    case 'Admin':
      if (status === 'pending') {
        return showButton;
      }
      if (status === 'paid') {
        return paymentLabel;
      }
      break;

    case 'Manager':
      if (status === 'pending') {
        return showButton;
      }
      if (status === 'paid') {
        return paymentLabel;
      }
      break;

    case 'Office':
      return paymentLabel;

    case 'Chef':
      return paymentLabel;

    case 'Accountant':
      return paymentLabel;

    default:
      return validatePermission(permissions, 'reimbursement', 'edit');
  }
};

const checkReimbursementDeletable = (roleName, permissions, reimbursementDate, periodStartDate, periodEndDate, reimbursementStatus) => {
  switch (roleName) {
    case 'Admin':
      return true;

    case 'Manager': {
      if (reimbursementStatus === "approved" || reimbursementStatus === "denied") {
        return false;
      }
      return true;
    }

    case 'Chef':
    case 'Accountant':
    case 'Office': {
      if (reimbursementStatus === "approved" || reimbursementStatus === "denied") {
        return false;
      }
      // eslint-disable-next-line no-else-return
      else {
        const endOfWeek = moment(periodEndDate)
          .utc()
          .endOf('week')
          .utcOffset(0)
          .format('YYYY-MM-DD');
        const endOfWeekPeriod = moment(endOfWeek)
          .add(3, 'days') // Add 3 days to go from Monday to Wednesday
          .format('YYYY-MM-DD');
        const currentDate = moment().format('YYYY-MM-DD');

        // Check if currentDate is within the periodStartDate and endOfWeekPeriod range
        const isWithinRange = moment(currentDate).isBetween(periodStartDate, endOfWeekPeriod, null, '[]');
        const isBetweenPeriod = moment(reimbursementDate)
          .isBetween(periodStartDate, periodEndDate, 'day', '[]');

        return isBetweenPeriod || isWithinRange;
      }
    }

    default:
      return validatePermission(permissions, 'reimbursement', 'delete');
  }
};

// This function modifies the filename to include the upload path and ensures the extension is correct
const generateFileNameWithPath = (originalName, isHeic) => {
  const basePath = process.env.REACT_APP_S3_RECEIPT_UPLOAD_PATH; // Assuming this is defined in your .env file
  const uuid = uuidv4();
  let extension = originalName.split('.').pop();

  if (isHeic) {
    extension = 'jpg'; // Convert .heic files to .jpg
  }

  return `${basePath}/${uuid}.${extension}`;
};

const fileValidationMsgs = {
  noReceiptAllowed:
    'Sorry, your eligibility for fuel reimbursement receipt upload has not been met.',

  limitReached:
    'You have reached the maximum limit for receipt uploads based on your current eligibility.',

  oneReceiptAllowed:
    'Only one fuel reimbursement receipt upload is allowed according to the current eligibility criteria.',

  twoReceiptsAllowed:
    'You can upload up to two fuel reimbursement receipts based on the current eligibility criteria.',
};

const displayErrorMsg = (message) => {
  toast.dark(<Toaster icon="error" message={message} />, { autoClose: 7000 });
};

const validateFuelReceipts = (
  hours,
  submittedReceipts,
  currentUploads,
  addedUploads
) => {
  if (hours < 64) {
    displayErrorMsg(fileValidationMsgs.noReceiptAllowed);
    return false;
  }

  if (hours >= 64 && hours <= 119.99) {
    if (submittedReceipts >= 1) {
      displayErrorMsg(fileValidationMsgs.limitReached);
      return false;
    }

    if (currentUploads >= 1 || addedUploads > 1) {
      displayErrorMsg(fileValidationMsgs.oneReceiptAllowed);
      return false;
    }
  }

  if (hours >= 120) {
    if (submittedReceipts >= 2) {
      displayErrorMsg(fileValidationMsgs.limitReached);
      return false;
    }

    if (submittedReceipts === 1) {
      if (currentUploads + addedUploads > 1) {
        displayErrorMsg(fileValidationMsgs.oneReceiptAllowed);
        return false;
      }
    }

    if (submittedReceipts === 0) {
      if (currentUploads + addedUploads > 2) {
        displayErrorMsg(fileValidationMsgs.twoReceiptsAllowed);
        return false;
      }
    }
  }
  return true;
};

const getAllBenefit = async (rbody) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const totalBenefitHours = [];
    const reportPromises = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const cityId of rbody?.service_city) {
      const cityBody = {
        employee: rbody.employee,
        service_city: cityId,
        startDate: rbody.startDate,
        endDate: rbody.endDate,
      };

      const report = generateHoursReport(cityBody).then((res) => {
        totalBenefitHours.push(
          res.data.data.report?.benefitHours[0]?.totalTimeTaken
        );
      });

      reportPromises.push(report);
    }

    await Promise.all(reportPromises);

    return totalBenefitHours.reduce((total, current) => total + current, 0);
  } catch (error) {
    throw error;
  }
};

export {
  checkApproveDenyButton, checkPaymentButton,
  checkReimbursementDeletable,
  generateFileNameWithPath, getPaymentStatusLabel, validateFuelReceipts, getAllBenefit
};

